/* App.css */

/* 自定义上传按钮样式 */
.custom-file-input:lang(en)::after {
  content: "选择文件";
}

.custom-file-input:lang(en)::before {
  content: "浏览";
}

/* 当文件选中后，通过JavaScript更改这个label的内容 */
.custom-file-label::after {
  content: "文件已选择";
}


/* ass table style */

table .comment td {
  background-color: #D8DEF5;
}

.table td,
.table th {
  padding: 0px;
  line-height: 1.1;
}


.table td {
  word-wrap: break-word;
  word-break: break-all;
  white-space: nowrap;
}

.table td:last-child,
.table th:last-child {
  white-space: normal;
}

/* table header */
#ass-header th {
  position: sticky;
  top: 0;
  background-color: white;
  z-index: 500;
  background-color: #A5CFE7;
}

/* small screan detect */
/* 默认隐藏提示信息 */
.landscape-prompt {
  display: none;
  position: fixed;
  /* 或absolute，根据需要 */
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  justify-content: center;
  align-items: center;
  text-align: center;
  z-index: 1000;
  /* 确保它覆盖其他内容 */
}

/* 屏幕宽度小于700px时显示提示 */
@media (max-width: 700px) {
  .landscape-prompt {
    display: flex;
  }
}